import "lazysizes"
// import a plugin
import "lazysizes/plugins/parent-fit/ls.parent-fit"
import React, {
  Suspense,
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import pageBuilder from "./Builder/index"
import { graphql } from "gatsby"
//  import "bootstrap/dist/css/bootstrap.min.css"
import { sortBy } from "lodash"
import SSRProvider from "react-bootstrap/SSRProvider"
import loadable from "@loadable/component"
import { ProviderAPI } from "./PageStateContext"
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3"
import { setCaptchaResponse } from "../redux/slices/states"
import { useDispatch } from "react-redux"
import { Head } from "./Builder/Head"

const FontSchemeManager = loadable(() => import("./FontScheme/index"))
const PrimeAgileHelmet = loadable(() => import("./Helmet/index"))
const ErrorBoundary = loadable(() => import("./ErrorBoundary/index"))


const ToastContainer = React.lazy(() =>
  import("react-toastify").then(module => {
    return { default: module.ToastContainer }
  })
)

export const PageStateContext = createContext(null)



export default function PrimeAgile({ data, pageContext }) {
  const [load, setLoad] = useState(false)

  const appendScriptsOnScrollOptions = { once: true }

  const addCaptha = () => {
    pageContext.loadcaptha = true
    setLoad(true)
  }

  useEffect(() => {
    window.addEventListener(
      "mousemove",
      addCaptha,
      appendScriptsOnScrollOptions
    )

    return () => {
      window.removeEventListener(
        "mousemove",
        addCaptha,
        appendScriptsOnScrollOptions
      )
    }
  }, [])

  let TREE =
    data.primeAgile?.layoutWithObjectTree?.uiObjectWithChildTreeR
      ?.uiObjectChildTree

  let LayoutCSS =
    data.primeAgile?.layoutWithObjectTree?.uiObjectWithChildTreeR?.jsonData?.css
      ?.fileUrl

  TREE = pageBuilder(sortBy(TREE, ["objectOrder"]))
  const dispatch = useDispatch()

  var verifyCallba = function (response) {
    dispatch(setCaptchaResponse(response))
  }

  // useEffect(() => {
  //   if (window.localStorage) {
  //     if (!localStorage.getItem("firstLoad")) {
  //       localStorage["firstLoad"] = true
  //       window.location.reload()
  //     } else localStorage.removeItem("firstLoad")
  //   }
  // }, []) // The effect will run whenever hasReloaded changes

  return (
    <>
      <SSRProvider>
        <ErrorBoundary>
          <ProviderAPI value={pageContext} id="primeagile__entry">
            <FontSchemeManager fontScheme={pageContext.fontScheme}>
              <Head
                pageContext={pageContext}
                LayoutCSS={LayoutCSS}
                pageCss={pageContext?.pageCss?.fileUrl}
              />
              <PrimeAgileHelmet
                pageContext={pageContext}
                LayoutCSS={LayoutCSS}
              />
              <Suspense fallback={<div />}>
                <ToastContainer />
              </Suspense>
              {pageContext?.loadcaptha && pageContext.captchaSiteKey ? (
                <GoogleReCaptchaProvider
                  reCaptchaKey={pageContext.captchaSiteKey}
                  scriptProps={{
                    async: false, // optional, default to false,
                    defer: true, // optional, default to false
                    appendTo: "head", // optional, default to "head", can be "head" or "body",
                    nonce: undefined, // optional, default undefined
                  }}
                >
                  <GoogleReCaptcha onVerify={verifyCallba} />
                
                    {TREE}
                </GoogleReCaptchaProvider>
              ) : (
                TREE
              )}
            </FontSchemeManager>
          </ProviderAPI>
        </ErrorBoundary>
      </SSRProvider>
    </>
  )
}

export const query = graphql`
  query layoutTree($site: String, $objectDataDefinitionId: String, $locale: String) {
    primeAgile {
      layoutWithObjectTree(
        objectDataDefinitionId: $objectDataDefinitionId
        layoutSetId: $site
        locale: $locale
      ) {
        css {
          fileId
          fileName
          fileType
          fileUrl
        }
        uiObjectWithChildTreeR {
          uiObjectChildTree
          jsonData
          type
          objectName
        }
      }
    }
  }
`
